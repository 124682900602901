import {
    Navbar
} from 'reactstrap';
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Logo from "./logo";
import {Link} from "gatsby";

class Header extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            collapsed: true
        }
    }

    toggle() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <Navbar color="white" light expand="lg" sticky={"top"} className="nav shadow p-3 mb-5 bg-white rounded">
                <Link to="/" className="my-0 mr-md-auto"><span className="navbar-brand text-muted"><Logo/> {this.props.siteTitle}</span></Link>
                <ul className="nav navbar-nav navbar-right">
                    <li>
                    <Link className="btn btn-primary" to="/kontakt">Kostenlos testen</Link>
                    </li>
                </ul>
            </Navbar>);
    }
}

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header
