import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql, withPrefix } from 'gatsby'

function SEO({ googleTitle, googleDescription,
               fbUrl, fbTitle, fbDescription, fbImage,
               twitterTitle, twitterDescription, twitterImage, twitterImageAlt,
               lang, meta, keywords }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={googleTitle}
            titleTemplate={`%s`}
            meta={[
              {
                name: `title`,
                content: googleTitle,
              },
              {
                name: `description`,
                content: googleDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:url`,
                content: fbUrl,
              },
              {
                property: `og:title`,
                content: fbTitle,
              },
              {
                property: `og:description`,
                content: fbDescription,
              },
              {
                property: `og:image`,
                content: fbImage,
              },
              {
                property: `fb:app_id`,
                content: `552113645280643`,
              },
              {
                property: `og:site_name`,
                content: `medoboard`,
              },
              {
                name: `twitter:site`,
                content: `@medoboard`,
              },
              {
                name: `twitter:title`,
                content: twitterTitle,
              },
              {
                name: `twitter:description`,
                content: twitterDescription,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:image`,
                content: twitterImage,
              },
              {
                name: `twitter:image:alt`,
                content: twitterImageAlt,
              }
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          >
            <link rel="stylesheet" href={withPrefix('bootstrap/4.3.1/css/bootstrap.min.css')} />
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  googleTitle: PropTypes.string.isRequired,
  googleDescription: PropTypes.string.isRequired,
  fbUrl: PropTypes.string,
  fbTitle: PropTypes.string,
  fbDescription: PropTypes.string,
  fbImage: PropTypes.string,
  twitterTitle: PropTypes.string,
  twitterDescription: PropTypes.string,
  twitterImage: PropTypes.string,
  twitterImageAlt: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string)
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
