import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, withPrefix, Link } from 'gatsby'
import './layout.css'
import Header from './header'
import { Container, Row, Col } from 'reactstrap'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} data={data} />
        <Container>
          <Row>
            <Col>{children}</Col>
          </Row>
          <Row>
            <Col>
              <footer className="text-muted text-center">
                © {new Date().getFullYear()} mit viel Liebe in Wien entwickelt.{' '}
                <div>
                  <Link to="/privacy">Datenschutzerklärung</Link>{' '}
                  <Link to="/imprint">Impressum</Link>
                </div>
              </footer>
            </Col>
          </Row>
        </Container>
        <script
          src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
          integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
          crossOrigin="anonymous"
        ></script>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js"
          integrity="sha384-ZMP7rVo3mIykV+2+9J3UJ46jBk0WLaUAdn689aCwoqbBJiSnjAK/l8WvCWPIPm49"
          crossOrigin="anonymous"
        ></script>
        <script
          src={withPrefix('bootstrap/4.3.1/js/bootstrap.min.js')}
        ></script>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
